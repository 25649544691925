"use client"
import React, { useEffect, useState } from "react";
import "./comment-ca-marche.css"

export default function CommentCaMarche() {

return (
<section className="comment-ca-marche">
    <div className="containerCCM">
        <h1 className="titleCCM center">Comment ça marche ?</h1>
        <br />
        <div className="section">
            <div className="row">
                <div className="rond center">
                    <p>1</p>
                </div>
            </div>
            <div className="row">
                <p className="center">Notre plateforme vous permet de rechercher rapidement vos objets perdus en ligne. Notre système intelligent parcourra notre base de données pour trouver des correspondances potentielles.</p>
            </div>
        </div>
        <div className="section">
            <div className="row">
                <div className="rond center">
                    <p>2</p>
                </div>
            </div>
            <div className="row">
                <p className="center">Une fois que vous avez trouvé un objet correspondant à votre recherche, notre plateforme vous guide à travers le processus de vérification de propriété. Vous serez invité à fournir des informations spécifiques sur l'objet perdu.</p>
            </div>
        </div>
        <div className="section">
            <div className="row">
                <div className="rond center">
                    <p>3</p>
                </div>
            </div>
            <div className="row">
                <p className="center">Une fois que vous avez prouvé votre propriété, vous pouvez choisir votre option de récupération préférée. En fonction de la situation, nous offrons deux possibilités : la récupération en mains propres ou la livraison sécurisée.</p>
            </div>
        </div>
        <br />
        <div className="section">
            <h2 className="center">A très vite dans notre établissement !</h2>
        </div>
    </div>
</section>
)}